import visaWhiteImage from "../assets/images/svg/visa-white.svg";
import mastercardWhiteImage from "../assets/images/svg/mastercard-white.svg";
import amexWhiteImage from "../assets/images/svg/amex-white.svg";
import rupayWhiteImage from "../assets/images/svg/rupay-white.svg";

import visaImage from "../assets/images/svg/visa.svg";
import mastercardImage from "../assets/images/svg/mastercard.svg";
import amexImage from "../assets/images/svg/amex.svg";
import rupayImage from "../assets/images/svg/rupay.svg";

export const VALIDATION_FORMATS = [
  "alphaNumeric",
  "alphaNumericWithSpace",
  "alphaNumericWithSpaceBracketsHyphen",
  "alphaNumericWithSpaceBracketsHyphenAndSymbols",
  "alphaNumericWithSymbols",
  "disableHtmlInput",
  "email",
  "number",
  "organizationName",
  "password",
  "standardpassword",
  "username",
];

export const CUSTOM_VALIDATION = [
  "allOrNoneFilled",
  "dateNotLessThan",
  "dateNotMoreThan",
  "mandatoryIfSiblingFilled",
  "updateSiblingIfFilled",
  "updateSiblingOnChange",
  "customTagMandatoryRule",
  "autoCompleteSiblingsNotSame",
];

export const AVATAR_TYPE = {
  FIRST_LETTER: 1,
  IMAGE: 2,
  SVG: 3,
  INDICATOR: 4,
};

export const LOG_LEVEL = {
  Debug: 0,
  Diagnostic: 1,
  Info: 2,
  Warn: 3,
  Error: 4,
};

// #region Iframe actions
export const IFRAME_ACTIONS = {
  GET_HEIGHT: "getIframeHeight",
  LOADED: "IframeLoaded",
  RESET_USER: "ResetUser",
  UPDATE_HEIGHT: "UpdateIframeHeight",
  BUTTON_CLICKED: "btnClickedAction",
  LOAD_BUTTON: "loadBtn",
};
// #endregion

// #region payment card
export const CARD_IMAGES = {
  0: visaImage,
  1: mastercardImage,
  6: amexImage,
  16: rupayImage,
};

export const CARD_WHITE_IMAGES = {
  0: visaWhiteImage,
  1: mastercardWhiteImage,
  6: amexWhiteImage,
  16: rupayWhiteImage,
};

export const PAYMENT_CARD_TYPES = {
  VISA: 0,
  MASTER_CARD: 1,
  AMEX: 6,
  RUPAY: 16,
};

// #endregion

export const PLURAL_STRINGS = {};
